$border-color: #ced4da;

.custom-select-container {
  position: relative;
  // min-width: 250px;
}

.custom-select-input {
  background-color: white;
  border-radius: 0.5em;
  padding: 0.5em 0.8em;
  width: 100%;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid $border-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.input-disabled {
    background-color: #e9ecef;
  }
  &.input-disabled:focus {
    outline: none;
    box-shadow: none;
    border-color: #ced4da;
  }
}

.custom-select-dropdown {
  background-color: white;
  border-radius: 0.5em;
  border-bottom: 1px solid $border-color;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  position: absolute;
  cursor: context-menu;
  top: 100%;
  width: 100%;
  z-index: 999;
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;
  left: 0%;
  transform: translateY(10px);

  & > div {
    padding: 0.25em 0.75em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    &:hover,
    &:focus,
    &.custom-option-selected {
      background-color: rgb(26, 157, 238);
      color: white;
    }
  }
}

@media screen and (max-width: 768px) {
  .custom-select-container {
    min-width: auto;
  }
}
