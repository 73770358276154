.invalid-validation {
    width: 100%;
    margin-top: 0.25rem;
    color: #dc3545;
}

.ck-editor__editable {
    min-height: 200px !important;
}

// table th:hover {
//     cursor: pointer;
// }

.pointer {
    cursor: pointer;
}

table th {
    color: #545cd8;
    text-align: center;
}

.anchor-link {
    color: #545cd8;
    cursor: pointer;
}

.anchor-link:hover {
    text-decoration: underline;
}

.list {
    opacity: 1;
    visibility: visible;
    display: block;
    transition: 0.3s ease;
}

.list.hidden {
    opacity: 0;
    visibility: hidden;
    display: none;
}

// box-shadow: inset 0 -3px 0 0 #545cd8;

.desc {
    box-shadow: inset 0 -3px 0 0 #545cd8;
}
.asc {
    box-shadow: inset 0 3px 0 0 #545cd8;
}
.imagesize {
    width: 100%;
    height: 220px;
    object-fit: contain;
}
.react-datepicker-popper {
    z-index: 20000 !important;
}

.luxury-level span::after {
    content: ", ";
}

.luxury-level span:last-child::after {
    content: "";
}
