
.userbox_avatar {
  border-radius: 50%;
  width: 54px;
  height: 54px;
  object-fit: cover;
  overflow: hidden;
}
.profileCard {
  position: relative;
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  overflow: hidden;

  &_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;

  }
 
  &:before {
    content: "";
    position: absolute;
    z-index: 1;
    width: 13.125rem;
    height: 13.125rem;
    background: #00BFC8;
    border-radius: 50%;
    top: -7.85rem;
    right: -1rem;
    opacity: 0.5;
  }

  &:after {
    content: "";
    position: absolute;
    width: 13.125rem;
    height: 13.125rem;
    background: #00BFC8;
    border-radius: 50%;
    z-index: 1;
    top: -5.25rem;
    right: -6rem;
  }
}

.cardWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

}


.profileWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  transition: all 1s;
  box-shadow: 0px 0px 10px 0px #FFF;

}

.avatar {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s;
  width: 10rem;
  height: 10rem;
  overflow: hidden;


  img {
    width: 100%;
    height: auto;
    border-radius: 50%;
  }

  input[type="file"] {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    z-index: 99999;
    width: 10rem;
    height: 10rem;
  }

  &:hover {
    transition: all 1s;
    box-shadow: 0px 0px 10px 2px #00bac4;
  }
}

.buttonGroup {
  width: 55%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 2rem;

  button {
    flex: 1;
    margin: 0 .3rem;
  }
}

// loading state
.spinner > div {
  border-radius: 100%;
  margin: 2px;
  animation-fill-mode: both;
  border: 2px solid #fff;
  border-bottom-color: transparent;
  height: 1rem;
  width: 1rem;
  background: transparent !important;
  display: inline-block;
  animation: rotate 0.75s 0s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(0.6);
  }
  100% {
   transform: rotate(360deg) scale(1);
 }
}