.context-menu-default {
  top: 50%;
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 1;
  transform-origin: top right;
  transition: transform 0.1s linear, opacity 0.2s linear 0.05s;
}

.context-menu-option-item {
  border-bottom: 1px solid transparent;
  &:hover {
    background-color: #d8d8d8;
  }
  &:nth-child(2n + 1) {
    border-bottom-color: #f6f6f6;
  }
}

.context-menu-link-item {
  display: inline-block;
  width: 100%;
}
