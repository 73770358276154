.vertical-timeline{
    padding-top:0px;
}

.dropdown-menu {

    &::after,
    &::before {
         display:none !important;
         visibility: hidden !important;
         background: transparent !important;
         height: 0 !important;
         width: 0 !important;
    }
}
