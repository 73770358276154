.App {
  font-family: sans-serif;
  text-align: center;
}

img {
  max-width: 100%;
}

/**workaround for accessibility artifact https://github.com/Hacker0x01/react-datepicker/issues/3924*/

/*.react-datepicker__aria-live {
  position: absolute;
  clip-path: circle(0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  width: 1px;
  white-space: nowrap;
}*/