$backgroundColor: #f7f7f7;

%table-format {
    margin: 1rem 0;
    
    label {
        border-bottom: 1px solid rgba(0, 69, 72, 0.125);
        padding: 10px 0;

        &:last-child {
            border-bottom: 0;
        }
    }
}
  
.booking {
    display: flex;
    align-items: center;

    &_name {
        margin-right: 1rem;
    }
   
}

.background {
    background-color: $backgroundColor;
}

.card {
    margin: 1rem 0;
    
}

.custom_card {
    background-color: $backgroundColor;
    @extend %table-format
}

.form_group {
    display: flex;
    margin-bottom: 0.25rem;
    align-items: baseline;

    label {
      
        margin: 0 .5rem 0 0;
        font-weight: 700;
       
    }

    input {
        
        border: 1px solid #00bac4;

        &:disabled {
            background-color: transparent;
            border: 0;
            padding: .25rem 0;
     
        }
    }
}

.footer {
    padding: 1rem 0 0 0;
}