.container {
  display: flex;
  flex-direction: row;
  align-items: center;

  button {
    text-align: left;
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: bold;
    padding: 0.375rem 0.3rem 0.375rem 0;
  }
}

.note_container {
  display: flex;
  flex-direction: column;
  
  .note {

    &:last-child {
     margin-bottom: .5rem;
    }
  }
  
  
}
.text {
  background: #45ccd4;
}
.phone {
  background: #d2c5ff;
}
.email {
  background: #ff7b74;
}
.date {
  color: #ff7b74 !important;
}

.details {
  line-height: 2;
}

.user_name {
  margin-left: 5px;
  color: #089d6b;
}
